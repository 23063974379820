import React from "react";

import Header from "./components/header/Header"
import About from "./components/about/About"
import Contact from "./components/contact/Contact"
import Experience from "./components/experience/Experience"
import Footer from "./components/footer/Footer"
import Nav2 from "./components/nav/Nav2"
import Testimonials from "./components/testimonials/Testimonials"
import Portfolio from "./components/portfolio/Portfolio"


export default function App(){
    return(
        <>
            <Header />
            <Nav2 />
            <About />
            <Experience />
            <Portfolio />
            <Testimonials />
            <Contact />
            <Footer />
           
        </>
    )
}